<template>
  <div class="main-menus">
    <ul class="menu flex flex-row items-center hidden xl:flex">
      <li v-if="!activeAccount">
        <btn @click.native="connectMetamask">Connect your wallet</btn>
      </li>
      <li v-else>
        <btn :connected="true" :disabled="true">Connected to metamask</btn>
      </li>
    </ul>
    <svg
      class="mobile-menu-icon xl:hidden cursor-pointer"
      @click="mobileMenu = true"
      height="30"
      viewBox="0 0 512 512"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m464.883 64.267h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z"
      />
      <path
        d="m464.883 208.867h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z"
      />
      <path
        d="m464.883 353.467h-417.766c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.012-21.137-47.149-47.117-47.149z"
      />
    </svg>
    <ul
      class="menu flex flex-col xl:hidden fixed top-0 h-full bg-black z-10 p-10"
      :class="{'menu-mobile-visible': mobileMenu, 'menu-mobile-invisible': !mobileMenu}"
    >
      <li>
        <svg
          @click="mobileMenu = false"
          class="absolute cursor-pointer top-4 left-4"
          id="mobile-menu-close"
          height="20"
          viewBox="0 0 329.26933 329"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
          />
        </svg>
      </li>
    </ul>
  </div>
</template>
<script>
import btn from "./btn.vue";
import { mapGetters } from "vuex";
export default {
  name: "mainMenu",
  data: () => {
    return {
      mobileMenu: false
    }
  },
  components: { btn },
  async mounted() {
    window.ethereum.on("accountsChanged", (accounts) => {
      this.handleChangeAccounts(accounts[0]);
    });
  },
  computed: {
    ...mapGetters("accounts", ["activeAccount"]),
  },
  methods: {
    goToSection(section) {
      document.getElementById(section).scrollIntoView({ behavior: "smooth" });
    },
    connectMetamask() {
      console.debug("connecting metamask");
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(() => {
          location.reload();
        })
        .catch((error) => {
          if (error.code === 4001) {
            // EIP-1193 userRejectedRequest error
            console.log("Please connect to MetaMask.");
          } else {
            console.error(error);
          }
        });
      location.reload();
    },
    handleChangeAccounts(account) {
      if (!account || account != this.activeAccount) {
        location.reload();
      }
    },
  },
};
</script>

<style scoped>
li {
  margin-left: 1.2rem;
}
.menu-mobile-invisible {
  right: -800px;
}
.menu-mobile-visible {
  right: 0;
}
.mobile-menu-icon {
  fill: white;
}
#mobile-menu-close {
  fill: white;
}
</style>
