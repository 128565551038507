<template>
  <div class="road-to-vortex flex flex-col items-center justify-center">
    <div id="road"></div>
    <div class="text-aboutus container mx-auto px-10 xl:px-40 text-center">
      <div class="steps flex flex-col lg:flex-row">
        <stepone class="lg:w-1/2 px-1 lg:px-6" />
        <steptwo class="lg:w-1/2 px-1 lg:px-6 roadmap-step" />

      </div>
      <!-- <additional-road-map /> -->
    </div>
  </div>
</template>
<script>
import stepone from "./roadmap/stepone.vue";
import steptwo from "./roadmap/steptwo.vue";
// import additionalRoadMap from "./additionalRoadMapSection.vue";
export default {
  name: "roadmap",
  components: { stepone, steptwo},
};
</script>
<style>
  .roadmap-step {
    min-height: 500px;
  }
</style>
