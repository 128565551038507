<template>
  <button
    :disabled="disabled"
    class="rounded px-6 py-2 uppercase font-sans text-xs font-bold"
    :class="{
      'bg-gray-500': disabled,
      'border-white hover:border-yellow-400 hover:text-yellow-400 border' : hollow && !disabled,
      'bg-blue-600 hover:bg-blue-800' : !hollow && !disabled,
      'bg-green-400' : connected,
      'text-xs' : !big && !disabled,
      'text-base' : big && !disabled
    }"
  >
    <slot />
  </button>
</template>
<script>
export default {
  name: "btn",
  props: {
    hollow: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    connected: {
      type: Boolean,
      default: false
    }
  }
}
</script>
