<template>
  <div class="roadmap roadmap-step mt-20 flex flex-col text-center">
    <img class="w-20 block mx-auto mb-10" src="@/assets/images/step2.png" />
    <h2 class="ringFont text-3xl">Teen Dragons emerge</h2>
    <h3 class="text-green-500 mb-6 font-black">Completed!</h3>
    <div class="flex flex-grow">
      <p>
        <span class="font-black">Teenage Dragons</span> are obviously in the
        mood for action and socializing! With their hormones raging.
        <span class="block text-xs mt-8"
          ><span class="font-black">TL;DR</span>
          <span class="font-black"> Two Teen Dragons</span> can Breed a Vortex Egg
          </span>
      </p>
    </div>
    <btn
      class="mt-6"
      @click.native="$router.push({ name: 'teenDragons' })"
      :hollow="true"
      >Know more</btn
    >
    <btn
      v-show="activeAccount"
      @click.native="$router.push({ name: 'mintTeens' })"
      class="mt-4"
      >Mint your Teen Dragon</btn
    >
  </div>
</template>
<script>
import btn from "../btn.vue";
import { mapGetters } from "vuex";
export default {
  name: "steptwo",
  components: { btn },
  computed: {
    ...mapGetters("accounts", ["activeAccount"]),
  },
};
</script>
