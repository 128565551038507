<template>
  <div class="tokens flex flex-col items-center justify-center">
    <div id="tokens"></div>
    <div class="text-aboutus container mx-auto px-10 xl:px-80 text-center">
      <h1 class="ringFont text-5xl mb-5">Our token</h1>
      <p class="text-center">Click on the Token to know more about it:
      </p>
      <div class="tokensWrapper flex flex-col lg:flex-row justify-center mt-10">
        <div class="fire-token mt-10 lg:mt-0 lg:text-left">
          <h2 class="ringFont text-4xl mb-4">
            FiRe
            <img
              class="inline-block ml-4"
              src="../assets/images/fire-token.png"
            />
          </h2>
          <p>
            Our <span class="font-black">Utility token</span>. Stake <span class="font-black">Baby Dragons</span> and use it to mint <span class="font-black">Teen Dragons</span>!
          </p>
          <btn :hollow="true" @click.native="$router.push({name: 'fireToken'})" class="mt-4">Know more</btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import btn from "./btn.vue";
export default {
    name: "tokens",
    components: { btn }
}
</script>
