<template>
    <div class="hero flex items-center justify-center py-20 relative ">
        <hero-img class="px-10 lg:px-0" />
        <!-- <hero-claim class="absolute px-10 lg:px-0" /> -->
    </div>
</template>
<script>
// import heroClaim from "./heroClaim.vue";
import heroImg from "./heroImg.vue";
export default {
    name: "hero",
    components: { heroImg }
}
</script>