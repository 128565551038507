import Vue from 'vue'
import Vuex from 'vuex'
import mightybabydragons from "./mightyBabyDragons";
import mtd from './mightyTeenDragons';
import fire from "./fire";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    mightybabydragons,
    mtd,
    fire
  }
})
