export default {
    namespaced: true,
    state: {
        checkingDragon: false,
        gettingDragons: false,
        allowingFire: false,
        mintingTeens: false,
        mintingStatus: true,
        mintingStatusTitle: "Teen Dragon incoming",
        mintingStatusText: "Your Teen Dragon is suscessfully minted",
        showModal: false,
    },
    mutations: {
        checkDragon(state, toggle) {
            state.checkingDragon = toggle;
        },
        getDragons(state, toggle) {
            state.gettingDragons = toggle;
        },
        allowFire(state, toggle) {
            state.allowingFire = toggle;
        },
        mintTeens(state, toggle) {
            state.mintingTeens = toggle;
        },
        launchModal(state, details) {
            state.mintingStatus = details.status;
            state.mintingStatusTitle = details.title;
            state.mintingStatusText = details.text;
            state.showModal = details.show;
        }
    },
    actions: {
        checkDragon(context, toggle) {
            context.commit("checkDragon", toggle)
        },
        getDragons(context, toggle) {
            context.commit("getDragons", toggle)
        },
        allowFire(context, toggle) {
            context.commit("allowFire", toggle)
        },
        mintTeens(context, toggle) {
            context.commit("mintTeens", toggle)
        },
        launchModal(context, details) {
            context.commit("launchModal", details)
        }
    }
}