<template>
  <div class="nav flex flex-row text-white items-center justify-between">
    <a class="logo" href="/"><img class="w-40 lg:w-52" src="../assets/images/mbd-logo.png" alt="Mighty Baby Dragons logo"></a>
    <main-menu/>
  </div>
</template>

<script>
import mainMenu from "./mainMenu.vue";
export default {
  name: "topMenu",
  components: {mainMenu}
}
</script>

<style scoped>
  .logo img {
    image-rendering: crisp-edges;
  }
</style>