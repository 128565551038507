import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/stake-baby-dragons",
    name: "stakeBabyDragons",
    component: () =>
      import(/* webpackChunkName: "stakeBabies" */ "../views/StakeBabies.vue"),
  },
  {
    path: "/teen-dragons",
    name: "teenDragons",
    component: () =>
      import(/* webpackChunkName: "teenDragons" */ "../views/TeenDragons.vue"),
  },
  // {
  //   path: "/vortex-dragons",
  //   name: "vortexDragons",
  //   component: () =>
  //     import(/* webpackChunkName: "vortexDragons" */ "../views/VortexDragons.vue"),
  // },
  {
    path: "/dragon-token",
    name: "dragonToken",
    component: () =>
      import(/* webpackChunkName: "dragonToken" */ "../views/DragonToken.vue"),
  },
  {
    path: "/fire-token",
    name: "fireToken",
    component: () =>
      import(/* webpackChunkName: "fireToken" */ "../views/FireToken.vue"),
  },
  {
    path: "/cave",
    name: "cave",
    component: () =>
      import(/* webpackChunkName: "cave" */ "../views/Cave.vue"),
  },
  {
    path: "/mint-teens",
    name: "mintTeens",
    component: () =>
      import(/* webpackChunkName: "mintTeens" */ "../views/mintTeens.vue"),
  },
  {
    path: "/toc",
    name: "toc",
    component: () =>
      import(/* webpackChunkName: "toc" */ "../views/Toc.vue"),
  },
  {
    path: "/last-com",
    name: "lastCom",
    component: () =>
        import(/* webpackChunkName: "com" */ "../views/lastComm.vue"),
  },
];

const router = new VueRouter({
  routes
});

export default router;
