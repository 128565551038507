var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"rounded px-6 py-2 uppercase font-sans text-xs font-bold",class:{
    'bg-gray-500': _vm.disabled,
    'border-white hover:border-yellow-400 hover:text-yellow-400 border' : _vm.hollow && !_vm.disabled,
    'bg-blue-600 hover:bg-blue-800' : !_vm.hollow && !_vm.disabled,
    'bg-green-400' : _vm.connected,
    'text-xs' : !_vm.big && !_vm.disabled,
    'text-base' : _vm.big && !_vm.disabled
  },attrs:{"disabled":_vm.disabled}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }