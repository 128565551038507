<template>
  <div
    class="mbd h-screen bg-no-repeat bg-contain"
    :style="{
      'background-image':
        'url(' + backgroundImage + ')',
    }"
  >
    <top-menu class="container pt-10 mx-auto px-6 lg:px-20" />
    <router-view></router-view>
    <footer class="mt-60 py-20 flex items-center justify-center">
      <a class="text-xs" href="mailto:hello@mightybabydragons.com">Contact us</a>
      <router-link class="text-xs ml-4" to="/toc">Terms and conditions</router-link>
    </footer>
  </div>
</template>
<script>
import topMenu from "@/components/topMenu";
export default {
  name: "MBD",
  components: { topMenu },
  computed: {
    backgroundImage() {
      return this.$route.name == "cave" ? require("./assets/images/cave.jpg") : require("./assets/images/background-image.jpg")
    }
  },
};
</script>
