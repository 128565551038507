<template>
  <div class="home">
    <hero-component/>
<!--    <about-us class="py-40 sm:py-60" />-->
    <road-to-vortex class="pb-40"/>
    <news class="pb-40"/>
    <tokens />
    <!-- <team /> -->
  </div>
</template>

<script>
import heroComponent from "@/components/heroComponent";
//import aboutUs from "@/components/aboutSection";
import roadToVortex from "@/components/roadToVortexSection";
import tokens from "@/components/tokensSection";

import news from "@/components/news";
export default {
  name: "Home",
  components: { heroComponent, roadToVortex, tokens, news }
}
</script>
