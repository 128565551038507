import Fire from "@/contracts/Fire.json";
import MightyBabyDragons from "@/contracts/MightyBabyDragons.json";
import MightyTeenDragons from "@/contracts/MightyTeenDragons.json";
const options = {
  web3: {
    block: false,
    fallback: {
      type: "ws",
      url: "ws://127.0.0.1:9545",
    },
  },
  events: {
    MightyBabyDragons: ["ApprovalForAll"],
    Fire: ["actionDone"],
    MightyTeenDragons: ["TeenDragonMinted"]
  },
  // The contracts to monitor
  contracts: [Fire, MightyBabyDragons, MightyTeenDragons],
  polls: {
    // check the blockchain every 5 seconds
    accounts: 3000,
  },
};
export default options;
