<template>
  <div class="roadmap roadmap-step text-left mt-20 flex flex-col text-center">
    <img class="w-20 block mx-auto mb-10" src="@/assets/images/step1.png" />

    <h2 class="ringFont text-3xl">Stake your Baby Dragons</h2>
    <h3 class="text-green-500 mb-6 font-black">Completed!</h3>
    <div class="flex flex-grow">
      <p>
        When a little <span class="font-black">Baby Dragon</span> stays in its
        <span class="font-black">Cave</span>, it starts to
        <span class="font-black">accumulate fire</span> at the rate of
        <span class="font-black">1 $FIRE </span>
        <img class="inline-block h-6" src="@/assets/images/fire-token.png" />
        <span class="font-black"> per day</span>
        <span class="block text-xs mt-8"
          ><span class="font-black">TL;DR</span> To get a <span class="font-black">Teen Dragon</span> you must
          have <span class="font-black">1 $FIRE</span> and own at least a
          <span class="font-black">non staked Baby Dragon</span></span
        >
      </p>
    </div>
    <btn
      class="mt-6"
      @click.native="$router.push({ name: 'stakeBabyDragons' })"
      :hollow="true"
      >Know more</btn
    >
    <btn
      v-show="activeAccount"
      @click.native="$router.push({ name: 'cave' })"
      class="mt-4"
      >Stake your Baby Dragons</btn
    >
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import btn from "../btn.vue";
export default {
  name: "stepone",
  components: { btn },
  created() {
    window.scrollTo(top);
  },
  computed: {
    ...mapGetters("accounts", ["activeAccount"]),
  },
};
</script>
